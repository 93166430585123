import { useSelector } from 'react-redux'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

const Notification = () => {
  const notification = useSelector(state => state.notification)

  return (
    <Box>
      {(notification.message && 
        <Alert severity={notification.messageType}>
          {notification.message}
        </Alert>
      )}
    </Box>
  )
}

export default Notification
