import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

const initialState = {
  startDate: dayjs().format('YYYY-MM-DD'),
  endDate: dayjs().format('YYYY-MM-DD'),
  sourceNode: 'ILLINOIS.HUB',
  sinkNode: 'INDIANA.HUB',
}

export const explorerSlice = createSlice({
  name: 'explorer',
  initialState,
  reducers: {
    setStartDate: (state, action) => {
      state.startDate = action.payload
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload
    },
    setSourceNode: (state, action) => {
      state.sourceNode = action.payload
    },
    setSinkNode: (state, action) => {
      state.sinkNode = action.payload
    },
  },
})

export const {
  setStartDate,
  setEndDate,
  setSourceNode,
  setSinkNode,
} = explorerSlice.actions

export default explorerSlice.reducer
