import { useGetPortfolioQuery } from '../../services/api'
import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  Cell,
  Tooltip,
  Label,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const Portfolio = () => {
  const { data, error, isLoading } = useGetPortfolioQuery()
  if (error) {
    return <p>Error!</p>
  }
  if (isLoading) {
    return <p>Loading...</p>
  }
  const currentPnl = data.data.slice(-1)[0].cumulativePnl
  const portfolioValue = Math.round((currentPnl + 50000) * 100) / 100
  const portfolioRoi = Math.round(((currentPnl / 50000) * 100) * 100) / 100

  return (
    <Box sx={{ width: '100%', height: '80vh'}}>
      <Typography
        align='center'
      >
        Profit & Loss Chart
      </Typography>
      <ResponsiveContainer width='100%' height='100%'>
        <ComposedChart
          margin={{ top: 10, right: 10, left: 10, bottom: 10 }}
          data={data.data}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            dataKey='invoice_date'
            axisLine={false}
          />
          <YAxis dataKey='pnl' yAxisId='left'>
            <Label
              style={{
                textAnchor: 'middle',
              }}
              angle={270}
              value='Daily P&L (USD)'
            />
          </YAxis>
          <YAxis dataKey='cumulativePnl' orientation='right' yAxisId='right'>
            <Label
              style={{
                textAnchor: 'middle',
              }}
              angle={90}
              value='Cumulative P&L (USD)'
            />
          </YAxis>
          <Tooltip />
          <ReferenceLine y={0} stroke='#000' yAxisId='left' />
          <Bar dataKey='pnl' yAxisId='left'>
            {
              data.data.map((entry, index) => {
                return <Cell key={`cell-${index}`} fill={entry.pnl < 0 ? '#E57373' : '#81C784'}/>
              })
            }
          </Bar>
          <Line dataKey='cumulativePnl' yAxisId='right' stroke='#ab47bc' />
        </ComposedChart>
      </ResponsiveContainer>
      <Typography
        align='center'
      >
        Current P&L: {currentPnl} USD
      </Typography>
      <Typography
        align='center'
      >
        Portfolio value: {portfolioValue} USD
      </Typography>
      <Typography
        align='center'
      >
        Portfolio ROI: {portfolioRoi} %
      </Typography>
    </Box>
  );
}

export default Portfolio
