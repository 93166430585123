import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ 
    baseUrl: '/api',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().login?.payload?.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    getPortfolio: builder.query({
      query: () => ({
        url: `portfolio`,
      }),
    }),
    getData: builder.query({
      query: () => ({
        url: `data`,
      }),
    }),
    getNodeName: builder.query({
      query: (params) => {
        const { tradeDate } = params
        return {
          url: `nodeName`,
          params: { tradeDate },
        }
      },
    }),
    getPrice: builder.query({
      query: (params) => {
        const { startDate, endDate, sourceNode, sinkNode } = params
        return {
          url: `price`,
          params: { startDate, endDate, sourceNode, sinkNode },
        }
      },
    }),
    getConstraint: builder.query({
      query: (params) => {
        const { tradeDate } = params
        return {
          url: `constraint`,
          params: { tradeDate },
        }
      },
    }),
    getFTR: builder.query({
      query: (params) => {
        const { startDate, endDate, sourceNode, sinkNode } = params
        return {
          url: `ftr`,
          params: { startDate, endDate, sourceNode, sinkNode },
        }
      },
    }),
  }),
})

export const {
  useGetPortfolioQuery,
  useGetDataQuery,
  useGetPriceQuery,
  useGetConstraintQuery,
  useGetFTRQuery,
  useGetNodeNameQuery,
} = api
