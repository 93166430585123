import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { useGetFTRQuery } from '../../../services/api'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Box from '@mui/material/Box'

const FTRTable = () => {
  const startDate = useSelector(state => state.explorer.startDate)
  const endDate = useSelector(state => state.explorer.endDate)
  const sourceNode = useSelector(state => state.explorer.sourceNode)
  const sinkNode = useSelector(state => state.explorer.sinkNode)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('ftr_value')

  const { data, error, isLoading } = useGetFTRQuery({
    startDate: dayjs(startDate).format('YYYY-MM-01'),
    endDate: dayjs(endDate).format('YYYY-MM-01'),
    sourceNode: sourceNode,
    sinkNode: sinkNode,
  })
  if (error) {
    return <p>Error!</p>
  }
  if (isLoading) {
    return <p>Loading...</p>
  }

  const handleSort = (newOrderBy) => {
    const isAsc = orderBy === newOrderBy && order === 'asc'
    const toggledOrder = isAsc ? 'desc' : 'asc'
    setOrder(toggledOrder)
    setOrderBy(newOrderBy)
  }

  const sortableRows = data.marketResult.slice()
  const visibleRows = sortableRows.sort((a, b) => {
    if (order === 'asc') {
      return a[orderBy] > b[orderBy] ? 1 : -1
    }
    return b[orderBy] > a[orderBy] ? 1 : -1
  })

  return (
    <TableContainer component={Box}>
      {data.marketResult.length > 0 &&
        <Table>
          <TableHead>
            <TableRow>
              {Object.keys(data.marketResult[0]).map((header) => {
                return (
                  <TableCell key={header}>
                    {/* <TableSortLabel */}
                    {/*   active={orderBy === header} */}
                    {/*   direction={orderBy === header ? order : 'asc'} */}
                    {/*   onClick={() => handleSort(header)} */}
                    {/* > */}
                      {header}
                    {/* </TableSortLabel> */}
                  </TableCell>)
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.marketResult.map((row) => {
              return (
                <TableRow key={`${row.source}-${row.sink}-${row.bid_type}-${row.peak}`}>
                  <TableCell>{row.trade_date}</TableCell>
                  <TableCell>{row.source}</TableCell>
                  <TableCell>{row.sink}</TableCell>
                  <TableCell>{row.bid_type}</TableCell>
                  <TableCell>{row.peak}</TableCell>
                  <TableCell>{Math.round(row.ftr_value * 100) / 100}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      }
      {data.shadowprices.length > 0 &&
        <Table>
          <TableHead>
            <TableRow>
              {Object.keys(data.shadowprices[0]).map((header) => {
                return (
                  <TableCell key={header}>
                    {/* <TableSortLabel */}
                    {/*   active={orderBy === header} */}
                    {/*   direction={orderBy === header ? order : 'asc'} */}
                    {/*   onClick={() => handleSort(header)} */}
                    {/* > */}
                      {header}
                    {/* </TableSortLabel> */}
                  </TableCell>)
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.shadowprices.map((row) => {
              return (
                <TableRow key={`${row.trade_date}-${row.source_sink}-${row.peak}`}>
                  <TableCell>{row.trade_date}</TableCell>
                  <TableCell>{row.source_sink}</TableCell>
                  <TableCell>{row.peak}</TableCell>
                  <TableCell>{Math.round(row.shadow_price * 100) / 100}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      }
    </TableContainer>
  )
}

export default FTRTable
