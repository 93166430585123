import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { api } from '../services/api'
import notificationReducer from './slice/notification/notificationSlice'
import loginReducer from './slice/login/loginSlice'
import uiReducer from './slice/ui/uiSlice'
import explorerReducer from './slice/explorer/explorerSlice'

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    notification: notificationReducer,
    login: loginReducer,
    ui: uiReducer,
    explorer: explorerReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
})

setupListeners(store.dispatch)
