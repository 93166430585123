import InputForm from './InputForm'
import PriceChart from './PriceChart'
import FTRTable from './FTRTable'
import ConstraintTable from './ConstraintTable'
import Box from '@mui/material/Box'

const Explorer = () => {
  return (
    <Box>
      <InputForm />
      <PriceChart />
      <FTRTable />
      {/* <ConstraintTable /> */}
    </Box>
  );
}

export default Explorer
