import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import loginService from '../../services/login'
import { notification } from '../../app/slice/notification/notificationSlice'
import { loginUser } from '../../app/slice/login/loginSlice'
import { toggleLoginForm } from '../../app/slice/ui/uiSlice'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

const LoginForm = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleLogin = async (event) => {
    event.preventDefault()

    try {
      const user = await loginService.login({
        username: event.target.username.value,
        password: event.target.password.value,
      })
      window.localStorage.setItem(
        'loggedWebUser', JSON.stringify(user)
      )
      dispatch(loginUser(user))
      dispatch(notification(`Succesfully logged in as ${user.name}`, 'success', 5))
      dispatch(toggleLoginForm())
      if (['kyle.fang@zeropointenergy.ca', 'temp@zeropointenergy.ca'].includes(user.username)) {
        navigate('/data')
      } else {
        navigate('/portfolio')
      }
    } catch (exception) {
      dispatch(notification('Wrong credentials', 'error', 5))
    }
  }

  return (
    <form onSubmit={handleLogin}>
      <Box 
        sx={{
          display: 'flex',
          height: '2.75rem',
          justifyContent: 'flex-end',
          alignItems: 'center',
          '> div, button': {
            marginLeft: '0.5rem',
            marginRight: '0.5rem',
          },
        }}
      >
        <TextField
          id='username'
          label='Username'
          variant='standard'
          value={username}
          onChange={(event) => { setUsername(event.target.value)}}
        />
        <TextField
          id='password'
          label='Password'
          variant='standard'
          type='password'
          value={password}
          onChange={(event) => { setPassword(event.target.value)}}
        />
        <Button
          variant='contained'
          type='submit'
        >
          Login
        </Button>
      </Box>
    </form>
  )
}

export default LoginForm
