import { useGetDataQuery } from '../../services/api'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'

const Data = () => {
  const { data, error, isLoading } = useGetDataQuery()
  if (error) {
    return <p>Error!</p>
  }
  if (isLoading) {
    return <p>Loading...</p>
  }

  return (
    <TableContainer component={Box}>
      <Table>
        <TableHead>
          <TableRow>
            {Object.keys(data.data.transactionId[0]).map((header) => {
              return (<TableCell key={header}>{header}</TableCell>)
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.data.transactionId.map((row) => {
            return (
              <TableRow key={row.transaction_id}>
                <TableCell>{row.owner}</TableCell>
                <TableCell>{row.trade_date}</TableCell>
                <TableCell>{row.transaction_id}</TableCell>
                <TableCell>{row.transaction_type}</TableCell>
                <TableCell>{row.transaction_time}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <Table>
        <TableHead>
          <TableRow>
            {Object.keys(data.data.tables[0]).map((header) => {
              return (<TableCell key={header}>{header}</TableCell>)
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.data.tables.map((row) => {
            return (
              <TableRow key={row.table_name}>
                <TableCell>{row.model_date}</TableCell>
                <TableCell>{row.trade_date}</TableCell>
                <TableCell>{row.table_name}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default Data
