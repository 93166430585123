import { createSlice } from '@reduxjs/toolkit'

const initialState = null

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginUser: (state, action) => {
      return action
    },
    logoutUser: (state) => {
      return null
    },
  },
})

// Action creators are generated for each case reducer function
export const { loginUser, logoutUser } = loginSlice.actions

export default loginSlice.reducer
