import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { useGetPriceQuery } from '../../../services/api'
import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  Cell,
  Tooltip,
  Label,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';
import Box from '@mui/material/Box'

const ChartContainer = () => {
  const startDate = useSelector(state => state.explorer.startDate)
  const endDate = useSelector(state => state.explorer.endDate)
  const sourceNode = useSelector(state => state.explorer.sourceNode)
  const sinkNode = useSelector(state => state.explorer.sinkNode)

  const { data, error, loading } = useGetPriceQuery({
    startDate: dayjs(startDate).format('YYYY-MM-DD'),
    endDate: dayjs(endDate).format('YYYY-MM-DD'),
    sourceNode: sourceNode,
    sinkNode: sinkNode,
  })
  if (error) {
    return <p>Error!</p>
  }
  if (loading) {
    return <p>Loading...</p>
  }
  if (!data) {
    return null
  }

  const getDomain = (data) => {
    let domainMin = 0
    let domainMax = 0

    data.price.map((row) => {
      if (row.sourceDelta < domainMin) {
        domainMin = row.sourceDelta
      }
      if (row.sinkDelta < domainMin) {
        domainMin = row.sinkDelta
      }
      if (row.sourceDelta > domainMax) {
        domainMax = row.sourceDelta
      }
      if (row.sinkDelta > domainMax) {
        domainMax = row.sinkDelta
      }
    })

    return [domainMin, domainMax]
  }

  const domain = getDomain(data)

  return (
    <Box sx={{ width: '100%', height: '80vh'}}>
      <ResponsiveContainer width='100%' height='100%'>
        <ComposedChart
          margin={{ top: 10, right: 10, left: 10, bottom: 10 }}
          data={data.price}
          stackOffset='sign'
        >
          <Tooltip />
          <YAxis dataKey='sourceDelta' domain={domain} yAxisId='left' />
          <YAxis dataKey='MTLF' domain={['auto', 'auto']} yAxisId='right' orientation='right' />
          <XAxis dataKey='hourEnding' axisLine={false} />
          <ReferenceLine y={0} stroke='#000' yAxisId='left' />
          <Bar dataKey='sourceDelta' yAxisId='left' stackId='delta' fill='#82CA9D' />
          <Bar dataKey='sinkDelta' yAxisId='left' stackId='delta' fill='#FF0000' />
          {/* <Bar dataKey='deltaMCC' yAxisId='left' stackId='CL' fill='#8884D8' /> */}
          {/* <Bar dataKey='deltaMLC' yAxisId='left' stackId='CL' fill='#FFC658' /> */}
          <Line dataKey='MTLF' yAxisId='right' />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
}

export default ChartContainer
