import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loginForm: false,
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleLoginForm: (state, action) => {
      state.loginForm = !state.loginForm
    },
  },
})

export const { toggleLoginForm } = uiSlice.actions

export default uiSlice.reducer
