import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom'
import Box from '@mui/material/Box'
import NavBar from './components/NavBar'
import Notification from './components/Notification'
import Portfolio from './components/Portfolio'
import Data from './components/Data'
import Explorer from './components/Explorer'

const App = () => (
  <Box>
    <Router>
      <Box>
        <Notification />
        <NavBar />
        <Routes>
          <Route path="/" element={<></>} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/data" element={<Data />} />
          <Route path="/explorer" element={<Explorer />} />
        </Routes>
      </Box>
    </Router>
  </Box>
)

export default App;
