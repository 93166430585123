import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/Box'
import { useGetNodeNameQuery } from '../../../services/api'
import { 
  setStartDate,
  setEndDate,
  setSourceNode, 
  setSinkNode, 
} from '../../../app/slice/explorer/explorerSlice'

const InputForm = () => {
  const dispatch = useDispatch()
  const startDate = useSelector(state => state.explorer.startDate)
  const endDate = useSelector(state => state.explorer.endDate)
  const sourceNode = useSelector(state => state.explorer.sourceNode)
  const sinkNode = useSelector(state => state.explorer.sinkNode)
  const { data, error, loading } = useGetNodeNameQuery({
    tradeDate: dayjs(startDate).format('YYYY-MM-DD'),
  })
  if (error) {
    return <p>Error!</p>
  }
  if (loading) {
    return <p>Loading...</p>
  }
  if (!data) {
    return null
  }

  return (
    <Box 
      sx={{
        display: 'flex',
        alignItems: 'center',
        '> div': {
          margin: '0.5rem',
        },
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker 
          label='Start Date'
          value={dayjs(startDate)}
          onChange={(newDate) => dispatch(setStartDate(dayjs(newDate).format('YYYY-MM-DD')))}
        />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker 
          label='End Date'
          value={dayjs(endDate)}
          onChange={(newDate) => dispatch(setEndDate(dayjs(newDate).format('YYYY-MM-DD')))}
        />
      </LocalizationProvider>
      <FormControl>
        <InputLabel id='source-node-input-label'>Source Node</InputLabel>
        <Select 
          labelId='source-node-input-label'
          id='source-node-input'
          label='Source Node'
          value={sourceNode}
          onChange={(event) => {
            dispatch(setSourceNode(event.target.value))
          }}
        >
          {data.nodeNames.map((nodeName) => {
            return (
              <MenuItem key={nodeName} value={nodeName}>{nodeName}</MenuItem>
            )
          })}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel id='sink-node-input-label'>Sink Node</InputLabel>
        <Select 
          labelId='sink-node-input-label'
          id='sink-node-input'
          label='Sink Node'
          value={sinkNode}
          onChange={(event) => {
            dispatch(setSinkNode(event.target.value))
          }}
        >
          {data.nodeNames.map((nodeName) => {
            return (
              <MenuItem key={nodeName} value={nodeName}>{nodeName}</MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </Box>
  )
}

export default InputForm
