import { useSelector, useDispatch } from 'react-redux'
import { toggleLoginForm } from '../../app/slice/ui/uiSlice'
import { logoutUser } from '../../app/slice/login/loginSlice'
import { notification } from '../../app/slice/notification/notificationSlice'
import {
  Link,
  useNavigate,
} from 'react-router-dom'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import LoginForm from '../LoginForm'

const NavBar = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const loginForm = useSelector(state => state.ui.loginForm)
  const user = useSelector(state => state.login?.payload?.name)

  const handleLoginButton = (event) => {
    event.preventDefault()
    dispatch(toggleLoginForm())
  }

  const handleLogoutButton = (event) => {
    event.preventDefault()
    window.localStorage.removeItem('loggedWebUser')
    dispatch(logoutUser())
    dispatch(notification('Succesfully logged out', 'success', 5))
    navigate('/')
  }

  return (
    <Box 
      sx={{
        display: 'flex',
        height: '2.75rem',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '> a, button': {
          marginLeft: '0.5rem',
          marginRight: '0.5rem',
        },
      }}
    >
      {user && <Typography>Logged in as {user}</Typography>}
      {loginForm && <LoginForm />}
      {user 
        ? <Button onClick={handleLogoutButton}>logout</Button>
        : <Button onClick={handleLoginButton}>login</Button>
      }
      {user && <Button component={Link} to='/portfolio'>portfolio</Button>}
      {['Kyle Fang', 'temp'].includes(user) && <Button component={Link} to='/data'>data</Button>}
      {['Kyle Fang', 'temp'].includes(user) && <Button component={Link} to='/explorer'>explorer</Button>}
      {/* <Button component={Link} to='/contact'>contact</Button> */}
    </Box>
  )
}

export default NavBar
