import { createAction, createSlice } from '@reduxjs/toolkit'

const initialState = {
  message: '',
  messageType: null,
  timer: null,
}

const setNotification = createAction('setNotification')
const clearNotification = createAction('clearNotification')

export const notification = (message, messageType, timeout) => {
  return async dispatch => {
    const timeoutId = setTimeout(() => dispatch(clearNotification()), timeout*1000)
    dispatch(
      setNotification({
        message,
        messageType,
        timer: timeoutId,
      })
    )
  }
}

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setNotification, (state, action) => {
        if (state.timer) {
          clearTimeout(state.timer)
        }
        return {
          message: action.payload.message,
          messageType: action.payload.messageType,
          timer: action.payload.timer,
        }
      })
      .addCase(clearNotification, (state, action) => {
        return initialState
      })
  },
})

export default notificationSlice.reducer
